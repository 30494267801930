import Typewriter from 'typewriter-effect/dist/core';

export function startTypewriterEffect(target) {
    const typewriter = new Typewriter(target, {});
  
    typewriter
      .typeString('Welcome')
      .pauseFor(2000)
      .deleteAll()
      .typeString('My name is Quest')
      .pauseFor(1000)
      .deleteAll()
      .typeString('I am a 3D Technical Artist, specializing in Real-Time Characters and Computer Vision')
      .pauseFor(1000)
      .deleteAll()
      .typeString('All of this was created from scratch using React :)')
      .pauseFor(1000)
      .deleteAll()
      .typeString('Here are some of my latest works:')
      .pauseFor(Infinity)
      .start();
  }
  
