import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import Home from './home/Home';

function Index() {
  return (
    <BrowserRouter>
      <Home />
    </BrowserRouter>
  );
}

createRoot(document.getElementById('root')).render(<Index />);
