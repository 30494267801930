import React from 'react';

const photos = [
  {
    imageUrl:"https://cdna.artstation.com/p/assets/covers/images/046/306/494/smaller_square/quest-matthews-martial-quest-matthews-martial-finsalepitch.jpg?1644808628",
    linkUrl:'https://www.artstation.com/artwork/xlDNY',
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/images/images/019/679/123/medium/quest-matthews-martial-5b6ff349533949-58b76ef034280.jpg?1564561013",
    linkUrl:'https://www.artstation.com/artwork/24gZx'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/images/images/032/293/345/medium/quest-matthews-martial-portrait.jpg?1606027845",
    linkUrl:'https://www.artstation.com/artwork/lxyZVz'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/images/images/004/728/259/20170130214517/smaller_square/quest-matthews-martial-1.jpg?1485834318",
    linkUrl:'https://www.artstation.com/artwork/LJzaK'
  },
  {
    imageUrl:"https://cdna.artstation.com/p/assets/covers/images/040/630/158/20210819184619/smaller_square/quest-matthews-martial-quest-matthews-martial-final.jpg?1629416779",
    linkUrl:'https://www.artstation.com/artwork/q918ke'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/covers/images/026/325/055/smaller_square/quest-matthews-martial-quest-matthews-martial-backonlycompressed.jpg?1588480246",
    linkUrl:'https://www.artstation.com/artwork/oOA6EL'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/covers/images/049/605/355/smaller_square/quest-matthews-martial-quest-matthews-martial-tech-art.jpg?1652880654",
    linkUrl:'https://www.artstation.com/artwork/xYKXzW'
  },
  {
    imageUrl:"https://cdna.artstation.com/p/assets/covers/images/014/164/660/smaller_square/quest-matthews-martial-artstationthumbnail.jpg?1542759178",
    linkUrl:'https://www.artstation.com/artwork/GX8vWQ'
  },
  {
    imageUrl:"https://cdna.artstation.com/p/assets/images/images/015/167/476/smaller_square/quest-matthews-martial-thumbnail.jpg?1547321334",
    linkUrl:'https://www.artstation.com/artwork/QzxLkd'
  },
  {
    imageUrl:"https://cdna.artstation.com/p/assets/covers/images/003/556/422/smaller_square/quest-matthews-martial-corridor2.jpg?1474981032",
    linkUrl:'https://www.artstation.com/artwork/KeXv4'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/covers/images/037/514/613/smaller_square/quest-matthews-martial-quest-matthews-martial-zb.jpg?1620589984",
    linkUrl:'https://www.artstation.com/artwork/lxDgNe'
  },
  {
    imageUrl:"https://cdnb.artstation.com/p/assets/covers/images/011/677/137/smaller_square/quest-matthews-martial-portraitfinal.jpg?1530809429",
    linkUrl:'https://www.artstation.com/artwork/vLQ0a'
  },
];

const GalleryItem = ({ imageUrl, linkUrl }) => {
  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="gallery-item">
      <img src={imageUrl} alt="Gallery item" />
    </a>
  );
};

const Gallery = () => {
  return (
    <div className="gallery">
      {photos.map((photo, index) => (
        <GalleryItem key={index} imageUrl={photo.imageUrl} linkUrl={photo.linkUrl} />
      ))}
    </div>
  );
};

export default Gallery;
